/* global angular */

'use strict';

angular.module('managerApp').config( ($routeProvider) => {
    $routeProvider.when('/frauds', {
        templateUrl: 'app/frauds/list.html',
        controller: 'FraudsController',
        authenticate: 'user',
        hasPermissions: ['FRAUDS:GET']
    });
});